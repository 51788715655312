@font-face {
  font-family: 'Quantum';
  src: url('/public/fonts/Quantum.ttf');
}

body {
  /* background: linear-gradient(-45deg,#1576b3, #1F648F, #29516A, #333f46); */
  background: linear-gradient(-45deg, #1576b3, #1F648F, #29516A);
  background-size: 400% 400%;
  /* animation: gradient 15s ease infinite; */
  height: 100vh;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.slide-txt {
  /* font-family: 'Deathrattle'; */
  /* font-size: 3em; */
  color: #fff;
}

.logo {
  max-height: 80px;
  display: inline;
}

.logo-text {
  padding-left: 12px;
  font-family: 'Mohave';
  font-weight: 700;
  font-size: 3em;
  color: #fff;
}

.discord-logo {
  max-height: 50px;
}

.mbtc-logo {
  max-height: 100px;
}

/* div {
  border: 2px solid pink;
} */

.swiper-slide {
  height: 100%;
  max-width: 100%;
}

.swiper-slide > div {
  position: relative;
  height: 400px;
  background-size: cover;
}

.slide-one {
  position: relative;
  background-image: url('/public/imgs/colony.png');
}

.slide-two {
  position: relative;
  background-image: url('/public/imgs/logistical-support.jpg');
}

.slide-three {
  position: relative;
  background-image: url('/public/imgs/doyourpart2.png');
}

.slide-four {
  position: relative;
  background-image: url('/public/imgs/bendrocity.png');
}

.slide-five {
  position: relative;
  background-image: url('/public/imgs/providence.png');
}

.discord-cell {
  background-color: #5865F2;
  color: #fff;
}

.bendrosafe {
  background-size: cover;
  background-image: url('/public/imgs/bendrosafe.png');
}

.bendronet {
  background-size: cover;
  background-image: url('/public/imgs/bendronet.png');
}

.pointer {
  cursor: pointer;
}

.recruiting {
  background-size: cover;
  background-image: url('/public/imgs/pax-bendro-wide.png');
}

.partner-bar > div {
  text-align: center;
}

/* .partner-bar > div > div {
  border: 2px solid pink;
} */

.partner-bar > div > img {
  max-height: 200px;
}

.pink-it {
  border: 2px solid pink;
}

.green-it {
  border: 2px solid green;
}

.subtext {
  font-family: 'Mohave', sans-serif;
  font-weight: 400;
  color: #fff;

}

button {
  font-family: 'Mohave', sans-serif;
}

h1{
  font-family: 'Mohave', sans-serif;
  font-weight: 700;
}

.bfont {
  font-family: 'Mohave', sans-serif;
  font-size: 1.2em;
  font-weight: 700;
}

footer {
  display: relative;
}

.no-select {
  user-select: none;
}

/* media query for when screen with is 650 */
@media screen and (max-width: 650px) {
  #top-bendronet-btn {
    display: none;
  }
}

@media screen and (max-width: 487px) {
  .logo-text {
    font-size: 2.75em;
  }
}

@media screen and (max-width: 455px) {
  .logo-text {
    font-size: 2em;
  }
}
