@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Mohave';
  src: url('/public/fonts/mohave/Mohave-Light.otf');
  font-weight: 300;

  font-family: 'Mohave';
  src: url('/public/fonts/mohave/Mohave-Medium.otf');
  font-weight: 400;

  font-family: 'Mohave';
  src: url('/public/fonts/mohave/Mohave-Bold.otf');
  font-weight: 700;
}

body {
  margin: 0;
  font-family: 'Mohave', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #29516A;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}